<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <div v-if="isAddNewUserSidebarActive">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ cashierData.id ? 'Editar' : 'Nuevo' }} Cajero
          </h5>

          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />

        </div>

        <!-- BODY -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >

            <!-- Image -->
            <div class="d-flex justify-content-center">
              <b-img
                ref="refPreviewEl"
                :src="cashierData.file"
                height="110"
                width="170"
                class="rounded mb-1 mb-md-0 cursor-pointer"
                @click="$refs.refInputEl.$refs.input.click()"
              />
            </div>
            <b-form-group
              v-if="cashierData.id"
              label="Imagen"
              label-for="file"
            >
              <b-form-file
                ref="refInputEl"
                v-model="cashierData.fileInput"
                accept=".jpg, .png, .webp"
                placeholder="Elija el archivo"
                @input="onChangeFile($event)"
              />
            </b-form-group>
            <validation-provider
              v-else
              #default="validationContext"
              name="Imagen"
              rules="required"
            >
              <b-form-group
                label="Imagen"
                label-for="file"
              >
                <b-form-file
                  ref="refInputEl"
                  v-model="cashierData.fileInput"
                  accept=".jpg, .png, .webp"
                  placeholder="Elija el archivo"
                  @input="onChangeFile($event)"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Selector -->
            <validation-provider
              #default="validationContext"
              name="Selector"
              rules="required"
            >
              <b-form-group
                label="Selector"
                label-for="selector"
              >
                <b-form-input
                  id="selector"
                  v-model="cashierData.selector"
                  :state="getValidationState(validationContext)"
                  autofocus
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Name -->
            <validation-provider
              #default="validationContext"
              name="Cajero"
              rules="required"
            >
              <b-form-group
                label="Cajero"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  v-model="cashierData.name"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Title -->
            <div class="position-relative">
              <feather-icon
                id="cashier-title-info-icon"
                icon="HelpCircleIcon"
                size="16"
                style="top: 0; right: 0;"
                class="position-absolute mr-75"
              />
              <b-tooltip
                title="Escriba el texto que se mostrará en la página"
                target="cashier-title-info-icon"
              />
              <validation-provider
                #default="validationContext"
                name="Título"
              >
                <b-form-group
                  label="Título"
                  label-for="title"
                >
                  <b-form-input
                    id="title"
                    v-model="cashierData.title"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>

            <!-- Type -->
            <validation-provider
              #default="validationContext"
              name="Tipo"
              rules="required"
            >
              <b-form-group
                label="Tipo"
                label-for="type"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="cashierData.type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="types"
                  :clearable="false"
                  input-id="type"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Countries -->
            <validation-provider
              #default="validationContext"
              name="Paises donde mostrar"
              rules="required"
            >
              <b-form-group
                label="Paises donde mostrar"
                label-for="countries"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="cashierData.countries"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="countries"
                  :clearable="false"
                  :close-on-select="false"
                  input-id="countries"
                  multiple
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Currencies -->
            <validation-provider
              #default="validationContext"
              name="Monedas"
              rules="required"
            >
              <b-form-group
                label="Monedas"
                label-for="currencies"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="cashierData.currencies"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="currencies"
                  :clearable="false"
                  :close-on-select="false"
                  input-id="currencies"
                  multiple
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Min limit -->
            <validation-provider
              #default="validationContext"
              name="Monto mínimo"
            >
              <b-form-group
                label="Monto mínimo"
                label-for="min_limit"
              >
                <b-form-input
                  id="min_limit"
                  v-model="cashierData.min_limit"
                  :state="getValidationState(validationContext)"
                  trim
                  @keydown="onKeydown"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Max limit -->
            <validation-provider
              #default="validationContext"
              name="Monto máximo"
            >
              <b-form-group
                label="Monto máximo"
                label-for="max_limit"
              >
                <b-form-input
                  id="max_limit"
                  v-model="cashierData.max_limit"
                  :state="getValidationState(validationContext)"
                  trim
                  @keydown="onKeydown"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Status -->
            <validation-provider
              name="Activo"
            >
              <b-form-group
                label="Activo"
                label-for="statusCheck"
              >
                <b-form-checkbox
                  id="statusCheck"
                  v-model="cashierData.status"
                  name="statusCheck"
                >
                  {{ cashierData.status ? 'Si' : 'No' }}
                </b-form-checkbox>
              </b-form-group>
            </validation-provider>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :disabled="loading"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                <template v-if="loading">
                  <b-spinner
                    variant="light"
                    class="mr-75"
                    small
                  />
                  <span>{{ cashierData.id ? 'Actualizando' : 'Creando' }}</span>
                </template>
                <span v-else>{{ cashierData.id ? 'Actualizar' : 'Crear' }}</span>
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                :disabled="loading"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Cancelar
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
  BSpinner,
  BFormFile,
  BImg,
  BTooltip,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { onMounted, ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { useFileToBase64 } from '@core/comp-functions/forms/form-utils'
// import countriesList from '@/@fake-db/data/other/countries'
// import store from '@/store'
import { typesCashiers } from '@core/constants'
import useCountries from '../countries/useCountries'
import useCashiers from './useCashiers'
import useCurrencies from '../currencies/useCurrencies'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormCheckbox,
    BSpinner,
    BFormFile,
    BImg,
    BTooltip,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      // countriesList,
    }
  },
  watch: {
    isAddNewUserSidebarActive(val) {
      if (val && this.item) {
        this.cashierData = {
          id: this.item.id,
          file: this.item.file,
          fileInput: null,
          name: this.item.name,
          title: this.item.title,
          selector: this.item.selector,
          type: this.item.type,
          countries: this.item.countries,
          currencies: this.item.currencies,
          min_limit: this.item.min_limit,
          max_limit: this.item.max_limit,
          status: this.item.status,
        }
      } else {
        this.cashierData = {
          id: null,
          file: null,
          fileInput: null,
          name: '',
          title: '',
          selector: '',
          type: '',
          countries: null,
          currencies: null,
          min_limit: null,
          max_limit: null,
          status: true,
        }
        this.$emit('update:item', null)
      }
    },
  },
  setup(_, { emit }) {
    const blankData = {
      id: null,
      file: null,
      fileInput: null,
      name: '',
      title: '',
      selector: '',
      type: '',
      countries: null,
      currencies: null,
      min_limit: null,
      max_limit: null,
      status: true,
    }

    const cashierData = ref(JSON.parse(JSON.stringify(blankData)))
    const resetData = () => {
      cashierData.value = JSON.parse(JSON.stringify(blankData))
    }

    const types = ref(typesCashiers)
    const loading = ref(false)
    const { cashierStore, cashierUpdate } = useCashiers()
    const { fetchCountriesSelector } = useCountries()
    const { fetchCurrenciesSelector } = useCurrencies()
    const countries = ref([])
    const currencies = ref([])

    const onKeydown = e => {
      // [Backspace, Tab, Enter, ArrowLeft, ArrowRight]
      const keys = [8, 9, 13, 37, 39]

      if ((e.keyCode < 48 || e.keyCode > 57) && !keys.includes(e.keyCode)) {
        e.preventDefault()
        e.stopPropagation()
      }
    }

    onMounted(async () => {
      const countryList = await fetchCountriesSelector()
      const currenciesList = await fetchCurrenciesSelector()

      countries.value = countryList
      currencies.value = currenciesList
    })

    const onSubmit = async () => {
      loading.value = true

      const data = { ...cashierData.value }

      delete data.fileInput

      if (data.id && data.file.startsWith('http')) {
        data.file = ''
      }

      try {
        if (data.id) {
          await cashierUpdate(data.id, data)
        } else {
          await cashierStore(data)
        }

        emit('refetchData')
        emit('update:is-add-new-user-sidebar-active', false)
      } catch (error) {
        console.log(error)
      } finally {
        loading.value = false
      }
    }

    const onChangeFile = async event => {
      const base64 = await useFileToBase64(event)

      cashierData.value.file = base64
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetData)

    return {
      cashierData,
      loading,
      onSubmit,

      types,
      countries,
      currencies,
      refFormObserver,
      getValidationState,
      resetForm,
      onChangeFile,

      onKeydown,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
