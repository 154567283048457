import CurrenciesRequest from '@/@api/currencies'
import { ref, computed } from '@vue/composition-api'
// import store from '@/store'
// import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { debouncedWatch } from '@vueuse/core'

const currencies = ref([])

export default function useCurrencies() {
  // Use toast
  const toast = useToast()

  const refCurrencyListTable = ref(null)

  // const currencies = ref([])

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: '#', sortable: true },
    { label: 'ISO', key: 'iso', sortable: true },
    {
      label: 'Moneda',
      key: 'name',
      sortable: true,
      thStyle: { width: '100%' },
    },
    { label: 'Activo', key: 'status', sortable: true },
    { label: 'Acciones', key: 'actions' },
  ]
  const perPage = ref(10)
  const totalCurrencies = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refCurrencyListTable.value ? refCurrencyListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCurrencies.value,
    }
  })

  const refetchData = () => {
    refCurrencyListTable.value.refresh()
  }

  debouncedWatch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  }, { debounce: 400 })

  const fetchCurrencies = (ctx, callback) => {
    CurrenciesRequest.list({
      params: {
        q: searchQuery.value,
        paginate: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        plan: planFilter.value,
        status: statusFilter.value,
      },
    })
      .then(response => {
        const { data, total } = response.data.data

        callback(data.map(currency => ({ ...currency, loading: false })))
        totalCurrencies.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching currencies list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  async function fetchCurrenciesSelector(isMemo = false) {
    if (isMemo && currencies.value.length) {
      return Promise.resolve(currencies.value)
    }

    try {
      const { data } = await CurrenciesRequest.select()

      const list = data.data.map(currency => ({
        id: currency.id,
        value: currency.iso?.toUpperCase() || currency.id,
        label: currency.name,
      }))

      currencies.value = list

      return list
    } catch (error) {
      throw new Error(error)
    }
  }

  async function currencyStore(body) {
    try {
      const { data } = await CurrenciesRequest.store(body)

      toast({
        component: ToastificationContent,
        props: {
          title: data.message,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    } catch (error) {
      throw new Error(error)
    }
  }

  async function currencyUpdate(id, body) {
    try {
      const { data } = await CurrenciesRequest.update(id, body)

      toast({
        component: ToastificationContent,
        props: {
          title: data.message,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    } catch (error) {
      throw new Error(error)
    }
  }

  async function currencyDelete(id) {
    try {
      const { data } = await CurrenciesRequest.destroy(id)

      toast({
        component: ToastificationContent,
        props: {
          title: data.message,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    } catch (error) {
      throw new Error(error)
    }
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  fetchCurrenciesSelector(true).then(list => {
    currencies.value = list
  })

  const resolveIsoCurrency = iso => {
    if (!iso) return ''

    const dic = currencies.value.reduce((acc, currency) => {
      acc[currency.value] = currency.label

      return acc
    }, {})

    return dic[iso] ?? iso
  }

  return {
    currencies,
    fetchCurrencies,
    fetchCurrenciesSelector,
    tableColumns,
    perPage,
    currentPage,
    totalCurrencies,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCurrencyListTable,

    resolveIsoCurrency,

    refetchData,
    currencyStore,
    currencyUpdate,
    currencyDelete,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
