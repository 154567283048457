import http from './http'
import Resource from './resource'

const path = 'admin/currencies'

class Currencies extends Resource {
  constructor(_http) {
    super(path, _http)
  }

  select(opt = {}) {
    return this.http.get(`api/${path}/select/list`, opt)
  }
}

const CurrenciesRequest = new Currencies(http)

export default CurrenciesRequest
