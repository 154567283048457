<template>
  <div>
    <cashiers-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :item.sync="cashierEditable"
      @refetchData="refetchData"
    />

    <b-row
      v-if="!loadingResume"
      class="justify-content-center"
    >
      <b-col
        v-for="(data, i) in resumeData.filter(data => data.currencies.length > 0)"
        :key="i"
        cols="12"
        lg="6"
        xl="3"
        class="mb-1"
      >
        <b-card
          no-body
          class="text-center px-1 pt-1 mb-1 h-100"
        >
          <h4>{{ data.country.name }}</h4>
          <span v-for="(currency, i) in data.currencies" :key="i">- {{ currency.name }} </span>
          <b-row class="align-items-center">
            <b-col cols="6" class="mt-1">
              <b-button
                block
                variant="primary"
                :disabled="data.depositCount === 0"
                @click="filterByResume(data, 'deposit')"
              >Depósitos activos: {{ data.depositCount }}
              </b-button>
            </b-col>
            <b-col cols="6" class="mt-1">
              <b-button
                block
                variant="secondary"
                :disabled="data.withdrawalCount === 0"
                @click="filterByResume(data, 'withdrawal')"
              >Retiros activos: {{ data.withdrawalCount }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="justify-content-center" v-else>
      <b-col v-for="(skeleton, i) in 3" :key="i" cols="12" lg="6" xl="3">
        <b-card no-body class="text-center my-1 px-1 py-1">
          <h3>Cargando</h3>
          <div class="d-flex justify-content-center">
            <b-skeleton type="button" height="6" width="50%"></b-skeleton>
          </div>
          <b-row class="align-items-center">
            <b-col cols="6" class="mt-1">
              <b-skeleton type="button" width="100%" height="35"></b-skeleton>
            </b-col>
            <b-col cols="6" class="mt-1">
              <b-skeleton type="button" width="100%" height="35"></b-skeleton>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-card
      class="mt-1"
      no-body
    >

      <b-row class="px-1 mt-2">
        <b-col
          cols="12"
          md="6"
        >
          <b-card-title class="mb-0 text-left">
            Cajeros
          </b-card-title>
        </b-col>

        <b-col
          cols="6"
          md="6"
          class="d-flex justify-content-end align-items-start"
        >
          <b-button-group class="mr-2">
            <b-button
              id="cashier-limits-icon"
              variant="outline-primary"
              class="btn-icon"
              @click="$router.push({ name: 'cashiers-limits' })"
            >
              <feather-icon
                icon="SlidersIcon"
                size="16"
              />
            </b-button>
            <b-tooltip
              title="Ver límites"
              target="cashier-limits-icon"
            />

            <b-button
              id="cashier-order-icon"
              variant="outline-primary"
              class="btn-icon p-50"
              @click="$router.push({ name: 'cashiers-order' })"
            >
              <feather-icon
                icon="FormatListBulletedSquare"
                size="22"
              />
            </b-button>
            <b-tooltip
              title="Ordenar cajeros"
              target="cashier-order-icon"
            />
          </b-button-group>

          <b-button-group class="mr-2">
            <b-button
              v-show="searchQuery"
              id="cashier-clear-filter-icon"
              variant="outline-danger"
              class="btn-icon"
              :disabled="loadingFilter"
              @click="clearFilter()"
            >
              <feather-icon
                icon="FilterOffOutline"
                size="16"
              />
            </b-button>
            <b-tooltip
              title="Limpiar filtros"
              target="cashier-clear-filter-icon"
            />

            <b-button
              variant="primary"
              :class="`${!searchQuery ? 'rounded' : ''}`"
              @click="toggleFilter = !toggleFilter"
            >
              <span class="text-nowrap">{{ toggleFilter ? 'Menos filtros' : 'Más filtros' }} </span>
            </b-button>
          </b-button-group>

          <b-button
            variant="primary"
            class="px-4"
            @click="isAddNewUserSidebarActive = true"
          >
            <span class="text-nowrap">Nuevo cajero</span>
          </b-button>
        </b-col>
      </b-row>
      <b-collapse v-model="toggleFilter" class="mt-2">
        <b-row class="mx-1 justify-content-end">
          <b-col cols="12" md="6" lg="4">
            <b-form-group label="Cajero">
              <b-form-input
                v-model="cashierFilter.cashier"
                label="Cajero"
                placeholder="Ingresa un cajero"
                :disabled="loadingFilter"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="4">
            <b-form-group label="Título">
              <b-form-input
                v-model="cashierFilter.title"
                label="Título"
                placeholder="Ingresa un título"
                :disabled="loadingFilter"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="4">
            <b-form-group label="Selector">
              <b-form-input
                v-model="cashierFilter.class"
                label="Selector"
                placeholder="Ingresa un selector"
                :disabled="loadingFilter"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="4">
            <b-form-group label="País">
              <v-select
                v-model="cashierFilter.country"
                :options="countries"
                item-text="label"
                item-value="id"
                multiple
                placeholder="Selecciona uno o varios paises"
                :disabled="loadingFilter"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="4">
            <b-form-group label="Moneda">
              <v-select
                v-model="cashierFilter.currency"
                :options="currencies"
                item-text="label"
                item-value="id"
                multiple
                placeholder="Selecciona una o varias moneda"
                :disabled="loadingFilter"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="4">
            <b-form-group label="Tipo">
              <v-select
                v-model="cashierFilter.type"
                label="text"
                :options="filterType"
                placeholder="Selecciona un tipo"
                :disabled="loadingFilter"
                :clearable="false"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="4">
            <b-form-group label="Activo">
              <v-select
                v-model="cashierFilter.active"
                label="text"
                placeholder="Selecciona un estado"
                :options="filterActive"
                :disabled="loadingFilter"
                :clearable="false"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="8" class="d-flex justify-content-end align-items-start mt-2">
            <b-button
              variant="primary"
              :disabled="loadingFilter"
              @click="filterList()"
            >
              <span class="text-nowrap">{{ loadingFilter ? 'Cargando...' : 'Filtrar listado' }}</span>
            </b-button>
          </b-col>
          <!-- v-model="bannerData.blacklist"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="countries"
          :disabled="loading"
          input-id="input-blacklist"
          multiple
          :close-on-select="false" -->
        </b-row>
      </b-collapse>

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <!-- <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label> -->
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >

            <div class="d-flex align-items-center justify-content-end">
              <!-- <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
              /> -->
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refCashierListTable"
        :items="fetchCashiers"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
        responsive
        primary-key="id"
        empty-text="No se encontraron cajeros"
        show-empty
        striped
      >
        <!-- Column: name -->
        <template #cell(name)="data">
          <b-media class="align-items-center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.image"
                :text="avatarText(data.item.name)"
                rounded="sm"
              />
            </template>
            <span>
              {{ data.item.name }}<br/>
              <small>{{ data.item.title }}</small>
            </span>
          </b-media>
        </template>

        <!-- Column: countries -->
        <template #cell(countries)="data">
          <div class="d-flex flex-column align-items-start">
            <div
              v-for="country in data.item.countries"
              :key="country.id"
              class="px-1"
              style="margin-bottom: 4px"
            >
              {{ country.name }}
            </div>
          </div>
        </template>

        <!-- Column: currencies -->
        <template #cell(currencies)="data">
          <div class="d-flex flex-column align-items-start">
            <div
              v-for="currency in data.item.currencies"
              :key="currency.id"
              class="px-1"
              style="margin-bottom: 4px"
            >
              {{ currency.name }}
            </div>
          </div>
        </template>

        <!-- Column: Type -->
        <template #cell(type)="data">
          {{ data.item.type ? typesDic[data.item.type] : '' }}
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          {{ data.item.status ? 'SI' : 'NO' }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`cashier-row-${data.item.id}-edit-icon`"
              icon="EditIcon"
              size="16"
              class="mx-1 cursor-pointer"
              @click="onEdit(data.item)"
            />
            <b-tooltip
              title="Editar cajero"
              :target="`cashier-row-${data.item.id}-edit-icon`"
            />

            <div class="d-inline">
              <feather-icon
                v-if="!data.item.loading"
                :id="`cashier-row-${data.item.id}-delete-icon`"
                icon="TrashIcon"
                size="16"
                class="cursor-pointer"
                @click="onDelete(data.item)"
              />
              <b-spinner
                v-if="data.item.loading"
                :id="`cashier-row-${data.item.id}-delete-icon`"
                small
              />
              <b-tooltip
                :title="data.item.loading ? 'Eliminando...' : 'Eliminar cajero'"
                :target="`cashier-row-${data.item.id}-delete-icon`"
              />
            </div>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando {{ dataMeta.from }} a {{ dataMeta.to }} de {{ dataMeta.of }} entradas</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalCashiers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BTable,
  BCard,
  BCardTitle,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BButtonGroup,
  BMedia,
  // BDropdownItem,
  BPagination,
  BTooltip,
  BSpinner,
  BAvatar,
  BFormGroup,
  BCollapse,
  // BSkeletonImg,
  BSkeleton,
} from 'bootstrap-vue'
import { onMounted, ref, reactive } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { typesCashiers } from '@core/constants'
// import FilterOffOutline from 'icons/FilterOffOutline.vue'
import CashiersAddNew from './CashiersAddNew.vue'
import useCashiers from './useCashiers'
import useCountries from '../countries/useCountries'
import useCurrencies from '../currencies/useCurrencies'

export default {
  components: {
    CashiersAddNew,
    BCard,
    BTable,
    BCardTitle,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BButtonGroup,
    BMedia,
    // BDropdownItem,
    BPagination,
    BTooltip,
    BSpinner,
    BAvatar,
    BFormGroup,
    BCollapse,
    // BSkeletonImg,
    BSkeleton,
    vSelect,
    // FilterOffOutline,
  },
  setup() {
    const {
      fetchCashiers,
      fetchCashiersResume,
      cashierDelete,
      tableColumns,
      perPage,
      currentPage,
      totalCashiers,
      dataMeta,
      perPageOptions,
      searchQuery,
      loadingFilter,
      loadingResume,
      sortBy,
      isSortDirDesc,
      refCashierListTable,
      refetchData,

      // UI
      // resolveUserRoleVariant,
      // resolveUserRoleIcon,
      // resolveUserStatusVariant,

      // Extra Filters
      // roleFilter,
      // planFilter,
      // statusFilter,
    } = useCashiers()
    const typesDic = ref([])
    typesDic.value = typesCashiers.reduce((a, c) => ({ ...a, [c.value]: c.label }), {})

    const isAddNewUserSidebarActive = ref(false)
    const cashierEditable = ref(null)

    const cashierFilter = reactive({
      cashier: '',
      title: '',
      class: '',
      country: [],
      currency: [],
      type: { text: 'Todos', value: '' },
      active: { text: 'Todos', value: '' },
    })
    const filterType = reactive([
      { text: 'Todos', value: '' },
      { text: 'Retiro', value: 'withdrawal' },
      { text: 'Deposito', value: 'deposit' },
    ])
    const filterActive = reactive([
      { text: 'Todos', value: '' },
      { text: 'Si', value: true },
      { text: 'No', value: false },
    ])
    const { fetchCountriesSelector } = useCountries()
    const { fetchCurrenciesSelector } = useCurrencies()
    const countries = ref([])
    const currencies = ref([])
    const resumeData = ref([])
    const toggleFilter = ref(false)

    onMounted(async () => {
      const countryList = await fetchCountriesSelector()
      const currenciesList = await fetchCurrenciesSelector()
      const testData = await fetchCashiersResume()

      countries.value = countryList
      currencies.value = currenciesList
      resumeData.value = testData
    })

    function filterList() {
      searchQuery.value = {
        cashier: cashierFilter.cashier,
        title: cashierFilter.title,
        class: cashierFilter.class,
        country: cashierFilter.country.map(element => element.id),
        currency: cashierFilter.currency.map(element => element.id),
        type: cashierFilter.type === null ? '' : cashierFilter.type.value,
        active: cashierFilter.active === null ? '' : cashierFilter.active.value,
      }
    }

    function clearFilter() {
      cashierFilter.cashier = ''
      cashierFilter.title = ''
      cashierFilter.class = ''
      cashierFilter.country = []
      cashierFilter.currency = []
      cashierFilter.type = { text: 'Todos', value: '' }
      cashierFilter.active = { text: 'Todos', value: '' }
      searchQuery.value = ''
    }

    function filterByResume(data, type) {
      searchQuery.value = {
        cashier: '',
        title: '',
        class: '',
        country: [data.country.id],
        currency: data.currencies.map(element => element.id),
        type,
        active: true,
      }
      toggleFilter.value = false
    }

    function onEdit(cashier) {
      cashierEditable.value = {
        id: cashier.id,
        file: cashier.image,
        fileInput: null,
        name: cashier.name,
        title: cashier.title,
        selector: cashier.selector,
        type: typesCashiers.find(e => e.value === cashier.type),
        countries: cashier.countries.map(e => ({
          id: e.id,
          label: e.name,
          value: e.iso,
        })),
        currencies: cashier.currencies.map(e => ({
          id: e.id,
          label: e.name,
          value: e.id,
        })),
        status: cashier.status,
      }

      isAddNewUserSidebarActive.value = true
    }

    async function onDelete(cashier) {
      try {
        const result = await this.$swal({
          title: '¿Estas seguro?',
          text: '¡No podrás revertir esta acción!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '¡Sí, bórralo!',
          cancelButtonText: 'Cancelar',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })

        if (result.value) {
          // eslint-disable-next-line no-param-reassign
          cashier.loading = true
          await cashierDelete(cashier.id)
          // eslint-disable-next-line no-param-reassign
          cashier.loading = false
          refetchData()

          this.$swal({
            icon: 'success',
            title: '¡Borrado!',
            text: 'El cajero ha sido borrado',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      } catch (error) {
        console.log(error)
      }
    }
    return {
      isAddNewUserSidebarActive,
      cashierEditable,
      refCashierListTable,

      tableColumns,
      sortBy,
      isSortDirDesc,
      perPage,
      currentPage,
      totalCashiers,
      dataMeta,
      perPageOptions,
      searchQuery,
      fetchCashiers,
      refetchData,
      onEdit,
      onDelete,
      avatarText,
      typesDic,

      cashierFilter,
      filterType,
      filterActive,
      countries,
      currencies,

      toggleFilter,
      loadingFilter,
      resumeData,
      loadingResume,
      filterList,
      clearFilter,
      filterByResume,
      fetchCashiersResume,
    }
  },
}
</script>
