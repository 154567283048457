import http from './http'
import Resource from './resource'

const path = 'admin/cashiers'

class Cashiers extends Resource {
  constructor(_http) {
    super(path, _http)
  }

  select(opt = {}) {
    return this.http.get(`api/${path}/select/list`, opt)
  }

  resume(opt = {}) {
    return this.http.get('api/admin/stats/cashiers', opt)
  }

  order(opt = {}) {
    return this.http.get('api/admin/cashiers-order', opt)
  }

  applyOrder(data, opt = {}) {
    return this.http.post('api/admin/cashiers-order', data, opt)
  }

  limits(opt = {}) {
    return this.http.get('api/cashiers/limits/all', opt)
  }

  limitsAdmin(opt = {}) {
    return this.http.get('api/admin/cashiers/limits/all', opt)
  }

  saveLimitCashier(data, opt = {}) {
    return this.http.post('api/admin/cashiers/limits/cashier', data, opt)
  }

  saveLimitCurrency(data, opt = {}) {
    return this.http.post('api/admin/cashiers/limits/currency', data, opt)
  }

  saveLimitUser(data, opt = {}) {
    return this.http.post('api/admin/cashiers/limits/user', data, opt)
  }
}

const CashiersRequest = new Cashiers(http)

export default CashiersRequest
